<script lang="ts">
    let className: string = "";
    export { className as class };
</script>

<svg class={className} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.74963 6.5C7.74963 4.15279 9.65242 2.25 11.9996 2.25C14.3468 2.25 16.2496 4.15279 16.2496 6.5C16.2496 8.84721 14.3468 10.75 11.9996 10.75C9.65242 10.75 7.74963 8.84721 7.74963 6.5ZM11.9996 0.25C8.54785 0.25 5.74963 3.04822 5.74963 6.5C5.74963 9.95178 8.54785 12.75 11.9996 12.75C15.4514 12.75 18.2496 9.95178 18.2496 6.5C18.2496 3.04822 15.4514 0.25 11.9996 0.25ZM11.9996 14.5C8.5357 14.5 5.06899 15.3319 2.09711 17.3297C2.08682 17.3366 2.07666 17.3438 2.06663 17.3511C1.73234 17.5943 1.46308 17.915 1.27866 18.2835L2.17287 18.7311L1.2786 18.2836C1.15071 18.5391 1.05016 18.7811 1.01182 19.0444C0.992376 19.178 0.993014 19.2887 0.995221 19.3642C0.996471 19.407 0.99749 19.429 0.998232 19.4451L0.998232 19.4451C0.999102 19.464 0.999591 19.4746 0.999625 19.5006V19.5H1.99963L0.999626 19.5011L0.999628 23.5001C0.999628 24.0524 1.44734 24.5001 1.99963 24.5001H21.9996C22.5519 24.5001 22.9996 24.0524 22.9996 23.5001V19.4703V19.4692H21.9996L22.9996 19.4703C23.0001 19.0589 22.905 18.652 22.7207 18.2836C22.5362 17.9151 22.2669 17.5943 21.9326 17.3511C21.9226 17.3438 21.9124 17.3366 21.9021 17.3297C18.9344 15.3347 15.6627 14.5 11.9996 14.5ZM3.06713 19.1787C3.10855 19.0959 3.16586 19.0276 3.23206 18.9767C5.80709 17.2521 8.85765 16.5 11.9996 16.5C15.3283 16.5 18.1889 17.2493 20.7672 18.9767C20.8334 19.0276 20.8907 19.0959 20.9321 19.1787C20.976 19.2663 20.9997 19.3657 20.9996 19.4681V19.4692V22.5001H2.99963L2.99963 19.5L2.99962 19.4989C2.99957 19.4497 2.99826 19.4072 2.99701 19.3741L2.99544 19.3347L2.99541 19.334C3.00632 19.3068 3.02766 19.2575 3.06707 19.1788L3.06713 19.1787Z"
        fill="currentColor" />
</svg>
