<script lang="ts">
    import IconUser from "@components/Icons/IconUser.svelte";
    import * as DropdownMenu from "$lib/components/ui/dropdown-menu";
    import { type UserCMS } from "../../../types/cmsTypes.ts";
    import { AxiosAstroAPI } from "../../../axios/AxiosAstroAPI";
    import { PROTECTED_ROUTES } from "../../../config/auth.config";

    export let user: UserCMS;

    const handleLogout = async () => {
        await AxiosAstroAPI.post("/api/auth/logout");
        if (PROTECTED_ROUTES.some((route) => window.location.pathname.startsWith(route))) {
            window.location.href = "/";
        } else {
            window.location.reload();
        }
    };

    function getLoginUrl() {
        const currentUrl = typeof window !== "undefined" ? window.location.href : "";
        return `${import.meta.env.PUBLIC_CMS_URL}/de/anmelden?redirecturl=${encodeURIComponent(currentUrl)}`;
    }
</script>

<DropdownMenu.Root>
    <DropdownMenu.Trigger>
        <div class="flex items-center gap-2">
            <IconUser class="h-5 w-5 cursor-pointer md:h-6 md:w-6" />
            {#if user?.stammOid && user.email}
                <span class="text-xxs hidden md:inline">{user.email}</span>
            {/if}
        </div>
    </DropdownMenu.Trigger>

    <DropdownMenu.Content class="w-48">
        {#if user?.stammOid}
            <DropdownMenu.Item on:click={handleLogout}>Abmelden</DropdownMenu.Item>
        {:else}
            <DropdownMenu.Item>
                <a href={getLoginUrl()} class="w-full"> Anmelden </a>
            </DropdownMenu.Item>
        {/if}
    </DropdownMenu.Content>
</DropdownMenu.Root>

<style>
    :global(.w-48) {
        width: 12rem;
    }
</style>
